@import './styles/Page.style.scss';
@import './styles/Global.style.scss';
@import './styles/Generic.style.scss';
@import './styles/Structure.style.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --blue: #03a9f4;
  --white: #fff;
  --white_blue: #f5fafd;
  --black: #000;
  --grey: #9e9e9e;
  --more-grey: #636e72;
  --red: #ff3838;
  --green: #2ecc71;
}
