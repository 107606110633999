.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.mt-6 {
  margin-top: 30px;
}

.mt-7 {
  margin-top: 35px;
}

.mt-8 {
  margin-top: 40px;
}

.mt-9 {
  margin-top: 45px;
}

.mt-10 {
  margin-top: 50px;
}

.mt-11 {
  margin-top: 55px;
}

.mt-12 {
  margin-top: 60px;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.mb-6 {
  margin-bottom: 30px;
}

.mb-7 {
  margin-bottom: 35px;
}

.mb-8 {
  margin-bottom: 40px;
}

.mb-9 {
  margin-bottom: 45px;
}

.mb-10 {
  margin-bottom: 50px;
}

.mb-11 {
  margin-bottom: 55px;
}

.mb-12 {
  margin-bottom: 60px;
}
