.basic_input {
  width: 80%;
  height: 35px;
  border: none;
  background-color: var(--white_blue);
  border-radius: 5px;
  padding-left: 15px;
  color: var(--black);
  &:focus {
    outline: 2px solid var(--blue);
  }
}

.basic_select {
  width: 85%;
  min-height: 35px;
  border: none;
  background-color: var(--white_blue);
  border-radius: 5px;
  padding-left: 15px;
  color: var(--black);
  &:focus {
    outline: 2px solid var(--blue);
  }
}

.basic_text_area {
  width: 80%;
  max-width: 80%;
  min-width: 80%;
  min-height: 100px;
  border: none;
  background-color: var(--white_blue);
  border-radius: 5px;
  padding-left: 15px;
  color: var(--black);
  &:focus {
    outline: 2px solid var(--blue);
  }
}

.input_label {
  width: 80%;
  color: var(--black);
  font-size: 14px;
}

.basic_button {
  background-color: var(--blue);
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: var(--white);
  font-size: 14px;
  font-weight: 800;
  width: 50%;
}

.basic_link {
  color: var(--blue);
  font-size: 14px;
  font-weight: 600;
  width: 80%;
}

.link_disconnect {
  color: var(--red);
  font-size: 16px;
  font-weight: 600;
  width: 80%;
  text-align: center;
}

.back_button {
  width: 30px;
  position: absolute;
  left: 30px;
  top: 30px;
  &:hover  {
    cursor: pointer;
  }
}

.back_button_header {
  width: 30px;
  position: absolute;
  left: 25px;
  top: 10px;
  &:hover  {
    cursor: pointer;
  }
}

.basic_card {
  width: 60vw;
  height: 60vw;
  margin: 5vw;
  background-color: var(--white_blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-shadow: -8px 7px 15px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -8px 7px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: -8px 7px 15px -10px rgba(0, 0, 0, 0.75);

  .icon_card {
    width: 50%;
  }
}

.header {
  background-color: var(--blue);
  height: 50px;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    color: var(--white);
    font-size: 18px;
  }

  .profile_icon {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
  }
}

.list_flight {
  width: 100vw;
  list-style: none;
  padding: 0;
  margin-top: 0;

  li {
    height: 80px;
    width: 100%;
    display: flex;
    padding: 0;

    .list_item_block_icon {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .name_flight {
        font-size: 12px;
        width: 80%;
        text-align: center;
      }
      img {
        width: 60%;
      }
    }

    .list_item_info {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .info_flight {
        font-size: 10px;
      }
    }
  }
}

.past-flight {
  margin-top: 2px;
  background: #9e9e9e;
}
.cancelled-flight {
  margin-top: 2px;
  background: #636e72;
}
.in-flight {
  margin-top: 2px;
  background: #03a9f4;
}
.updated-flight {
  margin-top: 2px;
  background: #f74b4b;
}

.pt-2 {
  padding-top: 10px;
}

.floating_button {
  width: 50px;
  height: 50px;
  position: fixed;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 100px;
  right: 20px;
  border-radius: 50%;
  -webkit-box-shadow: -0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -0px 0px 7px 0px rgba(0, 0, 0, 0.75);

  .icon {
    width: 25px;
  }
}

.background_blue {
  background-color: var(--blue);
}

.background_red {
  background-color: var(--red);
}

.background_green {
  background-color: var(--green);
}

.card_delay {
  background-color: var(--white_blue);
  min-height: 50px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .type {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
  }

  .remark {
    font-size: 12px;
    max-height: 50px;
    overflow-y: scroll;
    width: 80%;
    display: block;
    word-wrap: break-word;
  }

  .icon_trash {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
