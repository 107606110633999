.title_form {
  font-size: 24px;
  font-weight: 600;
  color: var(--black);
}

.simple_text {
  font-size: 14px;
  font-weight: 300;
  color: var(--black);
}

.form_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .flight_name {
    font-size: 18px;
    color: var(--primary);
    font-weight: 500;
  }
}

.date_selector {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 50px;
  align-items: center;
  border-bottom: solid 1px var(--grey);

  .selected_link {
    color: var(--blue);
    font-weight: 700;
  }
}

.no_data_block {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon_no_data {
    width: 50%;
  }
  .text_no_data {
    width: 80%;
    text-align: center;
    color: var(--black);
    font-size: 24px;
  }
}
